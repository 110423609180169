//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("event-settings")
@Component({
    selector: "event-settings",
    template: `
        <h2 class="c-margin-bottom--single">Event Search Settings</h2>
        <div class="ngBold c-margin-bottom--quarter">Event Search Options</div>
        <p class="ngFinePrint  c-margin-bottom--half">
            Select the basic options to make available for event searches throughout 25Live.
        </p>
        <search-options prefType="S" prefName="25L_event_system_options" objectName="event"></search-options>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSettingsComponent implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.cd.detectChanges();
    }
}
