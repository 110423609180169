//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("location-settings")
@Component({
    selector: "location-settings",
    template: `
        <h2 class="c-margin-bottom--single">Location Search Settings</h2>
        <div id="capBreakPoint" class="c-padding-bottom--double">
            <div class="ngBold c-margin-bottom--half">Location Capacity Breakpoints</div>
            <s25-breakpoint indexType="number" prefType="S" prefName="SpaceCapBreak"></s25-breakpoint>
        </div>
        <div id="searchOptions">
            <div class="ngBold c-margin-bottom--quarter">Location Search Options</div>
            <p class="ngFinePrint c-margin-bottom--half">
                Select the basic options to make available for location searches throughout 25Live.
            </p>
            <search-options prefType="S" prefName="25L_space_system_options" objectName="location"></search-options>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSettingsComponent implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns

        this.cd.detectChanges();
    }
}
