//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { EventSettingsComponent } from "./event.settings.component";
import { SearchOptionsModule } from "../search-options/search.options.module";

@NgModule({
    declarations: [EventSettingsComponent],
    imports: [CommonModule, FormsModule, SearchOptionsModule],
    providers: [EventSettingsComponent],
})
export class EventSettingsModule {
    constructor() {}
}
