//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PublisherSettingsComponent } from "./publisher.settings.component";

@NgModule({
    declarations: [PublisherSettingsComponent],
    imports: [CommonModule, FormsModule],
    providers: [PublisherSettingsComponent],
    exports: [PublisherSettingsComponent],
})
export class PublisherSettingsModule {
    constructor() {}
}
